/* tslint:disable */
/* eslint-disable */
/**
 * Digiform API
 * Digiform API pour les SGDF
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { MeetingModule } from './MeetingModule';
import {
    MeetingModuleFromJSON,
    MeetingModuleFromJSONTyped,
    MeetingModuleToJSON,
} from './MeetingModule';
import type { MeetingType } from './MeetingType';
import {
    MeetingTypeFromJSON,
    MeetingTypeFromJSONTyped,
    MeetingTypeToJSON,
} from './MeetingType';
import type { NotNestedPlace } from './NotNestedPlace';
import {
    NotNestedPlaceFromJSON,
    NotNestedPlaceFromJSONTyped,
    NotNestedPlaceToJSON,
} from './NotNestedPlace';
import type { NotNestedPublicMeetingParticipant } from './NotNestedPublicMeetingParticipant';
import {
    NotNestedPublicMeetingParticipantFromJSON,
    NotNestedPublicMeetingParticipantFromJSONTyped,
    NotNestedPublicMeetingParticipantToJSON,
} from './NotNestedPublicMeetingParticipant';
import type { OpinionStatusEnum } from './OpinionStatusEnum';
import {
    OpinionStatusEnumFromJSON,
    OpinionStatusEnumFromJSONTyped,
    OpinionStatusEnumToJSON,
} from './OpinionStatusEnum';
import type { StatusEnum } from './StatusEnum';
import {
    StatusEnumFromJSON,
    StatusEnumFromJSONTyped,
    StatusEnumToJSON,
} from './StatusEnum';
import type { StructureType } from './StructureType';
import {
    StructureTypeFromJSON,
    StructureTypeFromJSONTyped,
    StructureTypeToJSON,
} from './StructureType';
import type { TagadaStatusEnum } from './TagadaStatusEnum';
import {
    TagadaStatusEnumFromJSON,
    TagadaStatusEnumFromJSONTyped,
    TagadaStatusEnumToJSON,
} from './TagadaStatusEnum';
import type { Track } from './Track';
import {
    TrackFromJSON,
    TrackFromJSONTyped,
    TrackToJSON,
} from './Track';

/**
 * This Serializer allow us to get access to the current user
 * @export
 * @interface PatchedMeeting
 */
export interface PatchedMeeting {
    /**
     * 
     * @type {string}
     * @memberof PatchedMeeting
     */
    readonly id?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedMeeting
     */
    readonly label?: string;
    /**
     * 
     * @type {Date}
     * @memberof PatchedMeeting
     */
    readonly created_at?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PatchedMeeting
     */
    readonly end_date?: Date;
    /**
     * 
     * @type {MeetingType}
     * @memberof PatchedMeeting
     */
    readonly meeting_type?: MeetingType;
    /**
     * 
     * @type {string}
     * @memberof PatchedMeeting
     */
    readonly meeting_type_id?: string;
    /**
     * 
     * @type {NotNestedPlace}
     * @memberof PatchedMeeting
     */
    readonly place?: NotNestedPlace;
    /**
     * 
     * @type {string}
     * @memberof PatchedMeeting
     */
    readonly place_id?: string;
    /**
     * 
     * @type {Date}
     * @memberof PatchedMeeting
     */
    readonly start_date?: Date;
    /**
     * 
     * @type {StructureType}
     * @memberof PatchedMeeting
     */
    readonly structure_organisatrice?: StructureType;
    /**
     * 
     * @type {string}
     * @memberof PatchedMeeting
     */
    readonly structure_organisatrice_id?: string;
    /**
     * 
     * @type {Date}
     * @memberof PatchedMeeting
     */
    readonly updated_at?: Date;
    /**
     * 
     * @type {Array<MeetingModule>}
     * @memberof PatchedMeeting
     */
    modules?: Array<MeetingModule>;
    /**
     * 
     * @type {string}
     * @memberof PatchedMeeting
     */
    readonly daytime_min?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedMeeting
     */
    readonly daytime_max?: string;
    /**
     * 
     * @type {number}
     * @memberof PatchedMeeting
     */
    readonly ndays?: number;
    /**
     * 
     * @type {string}
     * @memberof PatchedMeeting
     */
    code_analytic?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedMeeting
     */
    readonly intranet_id?: string;
    /**
     * 
     * @type {Array<NotNestedPublicMeetingParticipant>}
     * @memberof PatchedMeeting
     */
    supervisors?: Array<NotNestedPublicMeetingParticipant>;
    /**
     * 
     * @type {string}
     * @memberof PatchedMeeting
     */
    num_agreement_js?: string;
    /**
     * Description du stage
     * @type {string}
     * @memberof PatchedMeeting
     */
    description?: string;
    /**
     * 
     * @type {StatusEnum}
     * @memberof PatchedMeeting
     */
    readonly status?: StatusEnum;
    /**
     * 
     * @type {Array<Track>}
     * @memberof PatchedMeeting
     */
    readonly tracks?: Array<Track>;
    /**
     * 
     * @type {string}
     * @memberof PatchedMeeting
     */
    tagada_password?: string;
    /**
     * 
     * @type {TagadaStatusEnum}
     * @memberof PatchedMeeting
     */
    readonly tagada_status?: TagadaStatusEnum;
    /**
     * 
     * @type {Date}
     * @memberof PatchedMeeting
     */
    readonly tagada_participants_last_sync?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PatchedMeeting
     */
    readonly tagada_avis_last_sync?: Date;
    /**
     * 
     * @type {number}
     * @memberof PatchedMeeting
     */
    tagada_total_candidates?: number;
    /**
     * 
     * @type {number}
     * @memberof PatchedMeeting
     */
    tagada_synced_candidates?: number;
    /**
     * 
     * @type {Date}
     * @memberof PatchedMeeting
     */
    readonly tagada_participants_sync_lock?: Date;
    /**
     * 
     * @type {OpinionStatusEnum}
     * @memberof PatchedMeeting
     */
    opinion_status?: OpinionStatusEnum;
    /**
     * 
     * @type {boolean}
     * @memberof PatchedMeeting
     */
    readonly has_opinions?: boolean;
}

/**
 * Check if a given object implements the PatchedMeeting interface.
 */
export function instanceOfPatchedMeeting(value: object): boolean {
    return true;
}

export function PatchedMeetingFromJSON(json: any): PatchedMeeting {
    return PatchedMeetingFromJSONTyped(json, false);
}

export function PatchedMeetingFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatchedMeeting {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'label': json['label'] == null ? undefined : json['label'],
        'created_at': json['created_at'] == null ? undefined : (new Date(json['created_at'])),
        'end_date': json['end_date'] == null ? undefined : (new Date(json['end_date'])),
        'meeting_type': json['meeting_type'] == null ? undefined : MeetingTypeFromJSON(json['meeting_type']),
        'meeting_type_id': json['meeting_type_id'] == null ? undefined : json['meeting_type_id'],
        'place': json['place'] == null ? undefined : NotNestedPlaceFromJSON(json['place']),
        'place_id': json['place_id'] == null ? undefined : json['place_id'],
        'start_date': json['start_date'] == null ? undefined : (new Date(json['start_date'])),
        'structure_organisatrice': json['structure_organisatrice'] == null ? undefined : StructureTypeFromJSON(json['structure_organisatrice']),
        'structure_organisatrice_id': json['structure_organisatrice_id'] == null ? undefined : json['structure_organisatrice_id'],
        'updated_at': json['updated_at'] == null ? undefined : (new Date(json['updated_at'])),
        'modules': json['modules'] == null ? undefined : ((json['modules'] as Array<any>).map(MeetingModuleFromJSON)),
        'daytime_min': json['daytime_min'] == null ? undefined : json['daytime_min'],
        'daytime_max': json['daytime_max'] == null ? undefined : json['daytime_max'],
        'ndays': json['ndays'] == null ? undefined : json['ndays'],
        'code_analytic': json['code_analytic'] == null ? undefined : json['code_analytic'],
        'intranet_id': json['intranet_id'] == null ? undefined : json['intranet_id'],
        'supervisors': json['supervisors'] == null ? undefined : ((json['supervisors'] as Array<any>).map(NotNestedPublicMeetingParticipantFromJSON)),
        'num_agreement_js': json['num_agreement_js'] == null ? undefined : json['num_agreement_js'],
        'description': json['description'] == null ? undefined : json['description'],
        'status': json['status'] == null ? undefined : StatusEnumFromJSON(json['status']),
        'tracks': json['tracks'] == null ? undefined : ((json['tracks'] as Array<any>).map(TrackFromJSON)),
        'tagada_password': json['tagada_password'] == null ? undefined : json['tagada_password'],
        'tagada_status': json['tagada_status'] == null ? undefined : TagadaStatusEnumFromJSON(json['tagada_status']),
        'tagada_participants_last_sync': json['tagada_participants_last_sync'] == null ? undefined : (new Date(json['tagada_participants_last_sync'])),
        'tagada_avis_last_sync': json['tagada_avis_last_sync'] == null ? undefined : (new Date(json['tagada_avis_last_sync'])),
        'tagada_total_candidates': json['tagada_total_candidates'] == null ? undefined : json['tagada_total_candidates'],
        'tagada_synced_candidates': json['tagada_synced_candidates'] == null ? undefined : json['tagada_synced_candidates'],
        'tagada_participants_sync_lock': json['tagada_participants_sync_lock'] == null ? undefined : (new Date(json['tagada_participants_sync_lock'])),
        'opinion_status': json['opinion_status'] == null ? undefined : OpinionStatusEnumFromJSON(json['opinion_status']),
        'has_opinions': json['has_opinions'] == null ? undefined : json['has_opinions'],
    };
}

export function PatchedMeetingToJSON(value?: Omit<PatchedMeeting, 'id'|'label'|'created_at'|'end_date'|'meeting_type'|'meeting_type_id'|'place'|'place_id'|'start_date'|'structure_organisatrice'|'structure_organisatrice_id'|'updated_at'|'daytime_min'|'daytime_max'|'ndays'|'intranet_id'|'status'|'tracks'|'tagada_status'|'tagada_participants_last_sync'|'tagada_avis_last_sync'|'tagada_participants_sync_lock'|'has_opinions'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'modules': value['modules'] == null ? undefined : ((value['modules'] as Array<any>).map(MeetingModuleToJSON)),
        'code_analytic': value['code_analytic'],
        'supervisors': value['supervisors'] == null ? undefined : ((value['supervisors'] as Array<any>).map(NotNestedPublicMeetingParticipantToJSON)),
        'num_agreement_js': value['num_agreement_js'],
        'description': value['description'],
        'tagada_password': value['tagada_password'],
        'tagada_total_candidates': value['tagada_total_candidates'],
        'tagada_synced_candidates': value['tagada_synced_candidates'],
        'opinion_status': OpinionStatusEnumToJSON(value['opinion_status']),
    };
}

