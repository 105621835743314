import {
  CommentsApi,
  Configuration,
  DailyTaskTeamsApi,
  EventsApi,
  ExportsApi,
  MeetingsApi,
  GestionnaireApi,
  ParticipantsApi,
  PlacesApi,
  ResourcesApi,
  RoomsApi,
  StructuresApi,
  TasksApi,
  TeamsApi,
  UsersApi,
  UsersDeontologieApi,
  FormsApi,
} from "@sgdf/client";

export class MeetingClient {
  conf: Configuration;

  commentsClient: CommentsApi | undefined;
  eventsClient: EventsApi | undefined;
  meetingsClient: MeetingsApi | undefined;
  participantsClient: ParticipantsApi | undefined;
  placeClient: PlacesApi | undefined;
  roomsClient: RoomsApi | undefined;
  structureClient: StructuresApi | undefined;
  tasksClient: TasksApi | undefined;
  teamsClient: TeamsApi | undefined;
  dailyTaskTeamClient: DailyTaskTeamsApi | undefined;
  meetingGestClient: GestionnaireApi | undefined;
  usersClient: UsersApi | undefined;
  usersDeontologieClient: UsersDeontologieApi | undefined;
  exportsClient: ExportsApi | undefined;
  resourcesClient: ResourcesApi | undefined;
  formsClient: FormsApi | undefined;

  constructor(conf: Configuration) {
    this.conf = conf;
  }

  getCommentsApi = () => {
    return (
      this.commentsClient || (this.commentsClient = new CommentsApi(this.conf))
    );
  };
  getEventsApi = () => {
    return this.eventsClient || (this.eventsClient = new EventsApi(this.conf));
  };
  getMeetingsApi = () => {
    return (
      this.meetingsClient || (this.meetingsClient = new MeetingsApi(this.conf))
    );
  };
  getParticipantsApi = () => {
    return (
      this.participantsClient ||
      (this.participantsClient = new ParticipantsApi(this.conf))
    );
  };
  getPlacesApi = () => {
    return this.placeClient || (this.placeClient = new PlacesApi(this.conf));
  };
  getRoomsApi = () => {
    return this.roomsClient || (this.roomsClient = new RoomsApi(this.conf));
  };
  getStructuresApi = () => {
    return (
      this.structureClient ||
      (this.structureClient = new StructuresApi(this.conf))
    );
  };
  getTasksApi = () => {
    return this.tasksClient || (this.tasksClient = new TasksApi(this.conf));
  };
  getTeamsApi = () => {
    return this.teamsClient || (this.teamsClient = new TeamsApi(this.conf));
  };

  getDailyTaskTeamApi = () => {
    return (
      this.dailyTaskTeamClient ||
      (this.dailyTaskTeamClient = new DailyTaskTeamsApi(this.conf))
    );
  };

  getGestionnaireApi = () => {
    return (
      this.meetingGestClient ||
      (this.meetingGestClient = new GestionnaireApi(this.conf))
    );
  };

  getUsersApi = () => {
    return this.usersClient || (this.usersClient = new UsersApi(this.conf));
  };

  getUsersDeontologieApi = () => {
    return (
      this.usersDeontologieClient ||
      (this.usersDeontologieClient = new UsersDeontologieApi(this.conf))
    );
  };

  getMeetings = async () => {
    const response = await this.getMeetingsApi().meetingsList();
    return {
      ...response,
      results: response.results,
    };
  };

  getExportsApi = () => {
    return (
      this.exportsClient || (this.exportsClient = new ExportsApi(this.conf))
    );
  };

  getResourcesApi = () => {
    return (
      this.resourcesClient ||
      (this.resourcesClient = new ResourcesApi(this.conf))
    );
  };

  getFormsApi = () => {
    return this.formsClient || (this.formsClient = new FormsApi(this.conf));
  };
}
