const deontology = "/deontologie";
const home = "/";
const login = "/login"; // On garde /login par simplicité avec l'interconnexion d'autres services
const gestionnaire = "/gestionnaire";
const gestionnaireMyScope = "/gestionnaire/my-scope";

// Equipe de Formation

function getMeetingHome(meetingId: string) {
  return `/stages/${meetingId}`;
}

function getMeetingParticipants(meetingId: string, queryParams?: Record<string, string>) {
  if (!queryParams) {
    return `/stages/${meetingId}/participants`;
  }
  return {
    path: `/stages/${meetingId}/participants`,
    query: queryParams
  };
}

function getMeetingParametersForFormers(meetingId: string) {
  return `/stages/${meetingId}/parametrage/formateurs`;
}

function getMeetingParametersForParticipants(meetingId: string) {
  return `/stages/${meetingId}/parametrage/participants`;
}

function getMeetingParametersForOpinionReview(meetingId: string) {
  return `/stages/${meetingId}/parametrage/opinion-review`;
}
function getMeetingParametersForTagada(meetingId: string) {
  return `/stages/${meetingId}/parametrage/tagada`;
}

function getMeetingTeams(meetingId: string) {
  return `/stages/${meetingId}/equipes`;
}

function getMeetingTeam(meetingId: string, teamId: string) {
  return `/stages/${meetingId}/equipes/${teamId}`;
}

function getMeetingDailyTaskTeam(meetingId: string, dailyTaskTeamId: string) {
  return `/stages/${meetingId}/equipes-de-vie/${dailyTaskTeamId}`;
}

function getMeetingNotes(meetingId: string) {
  return `/stages/${meetingId}/notes`;
}

function getMeetingNoteExport(meetingId: string, noteId: string) {
  return `/stages/${meetingId}/notes/${noteId}/export`;
}

function getMeetingParticipant(meetingId: string, queryParams?: Record<string, string>) {
  return {
    path: `/stages/${meetingId}/participant`,
    query: queryParams
  };
}

function getMeetingParticipantComment(meetingId: string, queryParams?: Record<string, string>) {
  return {
    path: `/stages/${meetingId}/participant`,
    query: {
      ...queryParams,
      view: "comment",
    }
  };
}

function getMeetingParticipantCheckIn(
  meetingId: string,
  participantId: string,
) {
  return `/stages/${meetingId}/participants/${participantId}/check-in`;
}

function getMeetingParticipantDetails(
  meetingId: string,
  queryParams?: Record<string, string>
) {
  return {
    path: `/stages/${meetingId}/participant`,
    query: {
      ...queryParams,
      view: "detail",
    }
  };
}

function getMeetingParticipantValidation(
  meetingId: string,
  queryParams?: Record<string, string>
) {
  return {
    path: `/stages/${meetingId}/participant`,
    query: {
      ...queryParams,
      view: "validation",
    }
  };
}

function getMeetingSchedule(meetingId: string) {
  return `/stages/${meetingId}/planning`;
}

function getMeetingScheduleForFormers(meetingId: string) {
  return `/stages/${meetingId}/planning-formateurs`;
}

function getMeetingParameters(meetingId: string) {
  return `/stages/${meetingId}/parametrage`;
}

function getMeetingParametersForTeams(meetingId: string) {
  return `/stages/${meetingId}/parametrage/equipes`;
}

function getMeetingParametersForDailyTasks(meetingId: string) {
  return `/stages/${meetingId}/parametrage/services`;
}

function getMeetingParametersForRooms(meetingId: string) {
  return `/stages/${meetingId}/parametrage/salles`;
}

function getMeetingParametersForLinks(meetingId: string) {
  return `/stages/${meetingId}/parametrage/documents`;
}

function getMeetingParametersForPlanning(meetingId: string) {
  return `/stages/${meetingId}/parametrage/planning`;
}

function getMeetingParametersForPlanningCreation(meetingId: string) {
  return `/stages/${meetingId}/parametrage/planning-creation`;
}

function getMeetingPlanningPrint(meetingId: string) {
  return `/stages/${meetingId}/planning-impression`;
}

function getMeetingParametersForExports(meetingId: string) {
  return `/stages/${meetingId}/parametrage/exports`;
}

function getMeetingForms(meetingId: string) {
  return `/stages/${meetingId}/parametrage/formulaires`;
}

function getMeetingFormEdition(meetingId: string, formId: string) {
  return `/stages/${meetingId}/parametrage/formulaires/${formId}`;
}

function getMeetingFormAnalysis(meetingId: string, formId: string) {
  return `/stages/${meetingId}/parametrage/formulaires/${formId}/reponses`;
}

function getMeetingFormView(meetingId: string, formId: string) {
  return `/stages/${meetingId}/formulaires/${formId}`;
}

function getMeetingLinks(meetingId: string) {
  return `/stages/${meetingId}/documents`;
}

function getDailyTasks(meetingId: string) {
  return `/stages/${meetingId}/services`;
}

function getMyDetails(meetingId?: string) {
  if (meetingId) {
    return `/stages/${meetingId}/mes-infos`;
  }
  return `/mes-infos`;
}

// Gestionnaire seulement
function getMeetingGestionnaireHome(meetingId: string) {
  return `${gestionnaire}/${meetingId}`;
}

function getMeetingGestionnaireOpinionReview() {
  return `${gestionnaire}/opinion-review`;
}

function getGestionnaireParticipantsExport() {
  return `${gestionnaire}/participants-export`;
}

export const RouteUtils = {
  getMeetingHome,
  deontology,
  home,
  login,
  gestionnaire,
  gestionnaireMyScope,
  getMeetingParameters,
  getMeetingParametersForTeams,
  getMeetingParametersForDailyTasks,
  getMeetingParametersForRooms,
  getMeetingParametersForLinks,
  getMeetingParametersForPlanning,
  getMeetingParametersForPlanningCreation,
  getMeetingParametersForExports,
  getMeetingSchedule,
  getMeetingScheduleForFormers,
  getMeetingLinks,
  getMeetingParticipants,
  getMeetingParticipant,
  getMeetingParticipantCheckIn,
  getMeetingParticipantDetails,
  getMeetingParticipantValidation,
  getMeetingParticipantComment,
  getMeetingGestionnaireHome,
  getMeetingGestionnaireOpinionReview,
  getGestionnaireParticipantsExport,
  getMeetingNotes,
  getMeetingTeams,
  getMeetingTeam,
  getMeetingDailyTaskTeam,
  getMeetingNoteExport,
  getMeetingParametersForFormers,
  getMyDetails,
  getMeetingPlanningPrint,
  getDailyTasks,
  getMeetingParametersForParticipants,
  getMeetingParametersForTagada,
  getMeetingParametersForOpinionReview,
  getMeetingFormEdition,
  getMeetingForms,
  getMeetingFormView,
  getMeetingFormAnalysis,
};

export function isUuid(value: string) {
  return value.match(
    /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/,
  );
}
