/* tslint:disable */
/* eslint-disable */
/**
 * Digiform API
 * Digiform API pour les SGDF
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ResponseTypeEnum } from './ResponseTypeEnum';
import {
    ResponseTypeEnumFromJSON,
    ResponseTypeEnumFromJSONTyped,
    ResponseTypeEnumToJSON,
} from './ResponseTypeEnum';

/**
 * This Serializer allow us to get access to the current user
 * @export
 * @interface NotNestedMeetingForm
 */
export interface NotNestedMeetingForm {
    /**
     * 
     * @type {string}
     * @memberof NotNestedMeetingForm
     */
    readonly id: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof NotNestedMeetingForm
     */
    readonly form_fields: Array<string>;
    /**
     * 
     * @type {ResponseTypeEnum}
     * @memberof NotNestedMeetingForm
     */
    response_type: ResponseTypeEnum;
    /**
     * 
     * @type {Date}
     * @memberof NotNestedMeetingForm
     */
    readonly created_at: Date;
    /**
     * 
     * @type {Date}
     * @memberof NotNestedMeetingForm
     */
    readonly updated_at: Date;
    /**
     * Date de publication du sondage
     * @type {Date}
     * @memberof NotNestedMeetingForm
     */
    publication_date?: Date;
    /**
     * Date d'ouverture du sondage
     * @type {Date}
     * @memberof NotNestedMeetingForm
     */
    opening_date?: Date;
    /**
     * Date de cloture du sondage
     * @type {Date}
     * @memberof NotNestedMeetingForm
     */
    closing_date?: Date;
    /**
     * 
     * @type {string}
     * @memberof NotNestedMeetingForm
     */
    author?: string;
    /**
     * 
     * @type {boolean}
     * @memberof NotNestedMeetingForm
     */
    is_open?: boolean;
    /**
     * 
     * @type {string}
     * @memberof NotNestedMeetingForm
     */
    title: string;
}

/**
 * Check if a given object implements the NotNestedMeetingForm interface.
 */
export function instanceOfNotNestedMeetingForm(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('form_fields' in value)) return false;
    if (!('response_type' in value)) return false;
    if (!('created_at' in value)) return false;
    if (!('updated_at' in value)) return false;
    if (!('title' in value)) return false;
    return true;
}

export function NotNestedMeetingFormFromJSON(json: any): NotNestedMeetingForm {
    return NotNestedMeetingFormFromJSONTyped(json, false);
}

export function NotNestedMeetingFormFromJSONTyped(json: any, ignoreDiscriminator: boolean): NotNestedMeetingForm {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'form_fields': json['form_fields'],
        'response_type': ResponseTypeEnumFromJSON(json['response_type']),
        'created_at': (new Date(json['created_at'])),
        'updated_at': (new Date(json['updated_at'])),
        'publication_date': json['publication_date'] == null ? undefined : (new Date(json['publication_date'])),
        'opening_date': json['opening_date'] == null ? undefined : (new Date(json['opening_date'])),
        'closing_date': json['closing_date'] == null ? undefined : (new Date(json['closing_date'])),
        'author': json['author'] == null ? undefined : json['author'],
        'is_open': json['is_open'] == null ? undefined : json['is_open'],
        'title': json['title'],
    };
}

export function NotNestedMeetingFormToJSON(value?: Omit<NotNestedMeetingForm, 'id'|'form_fields'|'created_at'|'updated_at'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'response_type': ResponseTypeEnumToJSON(value['response_type']),
        'publication_date': value['publication_date'] == null ? undefined : ((value['publication_date'] as any).toISOString()),
        'opening_date': value['opening_date'] == null ? undefined : ((value['opening_date'] as any).toISOString()),
        'closing_date': value['closing_date'] == null ? undefined : ((value['closing_date'] as any).toISOString()),
        'author': value['author'],
        'is_open': value['is_open'],
        'title': value['title'],
    };
}

