/* tslint:disable */
/* eslint-disable */
/**
 * Digiform API
 * Digiform API pour les SGDF
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `not_started` - Non démarré
 * * `in_progress` - Rédaction En cours
 * * `validated_director` - Validé par directeurice
 * * `validated_orga` - Validé national
 * * `submitted` - Finalisé - Soumis J&S
 * @export
 */
export const OpinionStatusEnum = {
    NotStarted: 'not_started',
    InProgress: 'in_progress',
    ValidatedDirector: 'validated_director',
    ValidatedOrga: 'validated_orga',
    Submitted: 'submitted'
} as const;
export type OpinionStatusEnum = typeof OpinionStatusEnum[keyof typeof OpinionStatusEnum];


export function instanceOfOpinionStatusEnum(value: any): boolean {
    return Object.values(OpinionStatusEnum).includes(value);
}

export function OpinionStatusEnumFromJSON(json: any): OpinionStatusEnum {
    return OpinionStatusEnumFromJSONTyped(json, false);
}

export function OpinionStatusEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): OpinionStatusEnum {
    return json as OpinionStatusEnum;
}

export function OpinionStatusEnumToJSON(value?: OpinionStatusEnum | null): any {
    return value as any;
}

