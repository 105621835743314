/* tslint:disable */
/* eslint-disable */
/**
 * Digiform API
 * Digiform API pour les SGDF
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `team` - Par équipe
 * * `individual` - Individuelle
 * @export
 */
export const ResponseTypeEnum = {
    Team: 'team',
    Individual: 'individual'
} as const;
export type ResponseTypeEnum = typeof ResponseTypeEnum[keyof typeof ResponseTypeEnum];


export function instanceOfResponseTypeEnum(value: any): boolean {
    return Object.values(ResponseTypeEnum).includes(value);
}

export function ResponseTypeEnumFromJSON(json: any): ResponseTypeEnum {
    return ResponseTypeEnumFromJSONTyped(json, false);
}

export function ResponseTypeEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResponseTypeEnum {
    return json as ResponseTypeEnum;
}

export function ResponseTypeEnumToJSON(value?: ResponseTypeEnum | null): any {
    return value as any;
}

